<template>
  <div class="classWork">
    <el-row class="search">
      <el-col :span="2">
        <el-image
          class="backIcon"
          @click="goBack"
          :src="require(`@/assets/image/blueBack.png`)"
          fit="contain"
        ></el-image
      ></el-col>
      <el-col :span="2" class="studentName">{{ studentName }}</el-col>
      <el-col :span="2">
        <el-button type="warning" @click="onSubmit">保存</el-button>
      </el-col>
    </el-row>
    <el-row class="work">
      <el-col
        class="workContent"
        v-for="item in detailData"
        :key="item.shop_user_data_job_id"
      >
        <div>
          <h3 v-if="item.type_order === 1">
            {{ REVERSERADIOTYPE[item.data_type] }}(共{{
              typeTotalObj[item.data_type] && typeTotalObj[item.data_type].num
            }}题，{{
              typeTotalObj[item.data_type] &&
              typeTotalObj[item.data_type].total
            }}分)
          </h3>
          <div class="title">
            <div>
              {{ item.type_order }}、{{ item.data_questionText }}（{{
                item.data_score
              }}）分
            </div>
            <span>得分：</span>
            <el-input
              class="scoreInput"
              size="medium"
              v-model="item.job_score"
            ></el-input>
          </div>
          <!-- 单选题或者判断题 -->
          <el-radio-group
            v-model="item.job_analysis"
            v-if="item.data_type === 1 || item.data_type === 4"
            disabled
          >
            <div
              class="option"
              v-for="item in item.option"
              :key="item.shop_user_data_option_id"
            >
              <el-radio :label="item.shop_user_data_option_id">{{
                item.option_content
              }}</el-radio>
            </div>
          </el-radio-group>
          <!-- 多选题 -->
          <el-checkbox-group
            v-model="item.job_analysis"
            v-if="item.data_type === 2"
            disabled
          >
            <div
              class="option"
              v-for="item in item.option"
              :key="item.shop_user_data_option_id"
            >
              <el-checkbox :label="item.shop_user_data_option_id">{{
                item.option_content
              }}</el-checkbox>
            </div>
          </el-checkbox-group>
          <!-- 填空||解答题 -->
          <el-input
            disabled
            v-if="item.data_type === 3 || item.data_type === 5"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10 }"
            placeholder="请输入内容"
            v-model="textarea"
          >
          </el-input>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getMyEntrepotDetail, insertDataJob } from "@/request/api";
export default {
  data() {
    return {
      detailData: [],
      studentName: "", // 学生名字
      entrepot_name: "", // 作业名
      totalQuantity: 0, // 题量
      totalPoints: 0, // 总分
      REVERSERADIOTYPE: {
        1: "单选题",
        2: "多选题",
        3: "填空题",
        4: "判断题",
        5: "简答题",
      },
      typeTotalObj: {},
      checkProblem: {}, // 选中的题目
    };
  },
  methods: {
    async onSubmit() {
      const workList = [...this.detailData];
      const body = {
        isNew: true,
        shop_user_class_entrepot_job_id:
          this.$route.query.shop_user_class_entrepot_job_id,
        data: workList.map((item) => {
          const newItem = { ...item };
          if (newItem.job_analysis instanceof Array) {
            newItem.job_analysis = newItem.job_analysis.join(",");
          }
          return newItem;
        }),
      };
      const res = await insertDataJob(body);
      if (res.data.code === "1000") {
        this.$message.success("保存成功");
        this.init(this.$route.query.shop_user_class_entrepot_job_id);
      }
    },
    goBack() {
      this.$router.back();
    },
    sendExit() {
      this.$emit("update-value");
    },
    init(shop_user_class_entrepot_job_id) {
      getMyEntrepotDetail(shop_user_class_entrepot_job_id).then((res) => {
        if (res.data.code === "1000") {
          this.detailData = res.data.data.data;
          this.totalQuantity = res.data.data.data.length;
          this.totalPoints = res.data.data.data.reduce((prev, curr) => {
            return prev + curr.data_score;
          }, 0);

          let newTypeTotalObj = {}; // 每种题型的总分
          let totalP = 0; // 总分
          const data = this.detailData.map((item) => {
            // const lastItem = this.detailData[index > 0 ? index - 1 : 0];

            if (item.data_score) {
              totalP += item.data_score; // 所有总分
              // 计算每种题型的总分

              newTypeTotalObj[item.data_type] = newTypeTotalObj[item.data_type]
                ? newTypeTotalObj[item.data_type]
                : { total: 0, num: 0 };
              newTypeTotalObj[item.data_type].total =
                newTypeTotalObj[item.data_type].total + item.data_score;
              newTypeTotalObj[item.data_type].num =
                newTypeTotalObj[item.data_type].num + 1;
            }

            const result = item.data_question
              .match(/>([^<]+)</g)
              .map((s) => s.slice(1, -1).trim());
            item.data_questionText = result[0];

            if (item.data_type === 2) {
              if (item.job_analysis) {
                item.job_analysis = item.job_analysis
                  .split(",")
                  .map((item) => item * 1);
              } else {
                item.job_analysis = [];
              }
            }
            if (item.data_type === 1 || item.data_type === 4) {
              item.job_analysis = item.job_analysis * 1;
            }
            if (
              !item.job_analysis ||
              item.job_analysis.length === 0 ||
              item.job_analysis === ""
            ) {
              item.isDone = false;
            } else {
              item.isDone = true;
            }
            return item;
            // 插入每种类型的标题
          });
          this.detailData = data;
          this.totalPoints = totalP;
          this.typeTotalObj = newTypeTotalObj;
          // this.checkProblem = this.detailData[0];
        }
      });
    },

    conversion(time) {
      const date = new Date(time);
      const Y = date.getFullYear();
      const M = date.getMonth() + 1;
      const D = date.getDate();
      return Y + "-" + M + "-" + D;
    },
  },
  mounted() {
    this.studentName = this.$route.query.name;
    this.init(this.$route.query.shop_user_class_entrepot_job_id);
  },
};
</script>

<style lang="less" scoped>
.classWork {
  .chat-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      display: flex;
    }
    .myWork {
      width: 90px;
      line-height: 26px;
      cursor: pointer;
      color: #2a6ee9;
      font-size: 16px;
    }
    .backIcon {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }

  .work {
    height: 550px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .search {
    width: 100%;
    background: #fff;
    border-radius: 8px 8px 8px 8px;
    padding: 20px 40px;
    margin: 30px 0;
    line-height: 38px;
    // font-size: 18px;
    .backIcon {
      width: 34px;
      height: 34px;
      cursor: pointer;
    }
    .studentName {
      color: #61a0ff;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .workTitleList {
    width: 31%;
    padding: 20px;
    border-radius: 8px 8px 8px 8px;
    background: #fff;
    // margin-right: 20px;
    overflow-y: auto;
    .totalText {
      font-weight: 400;
      font-size: 14px;
      color: #929292;
    }
    .topicTitle {
      display: flex;
      margin: 10px 0;
    }
    .topic {
      font-size: 14px;
      color: #61a0ff;
      font-weight: 400;
      line-height: 24px;
      cursor: pointer;
      display: flex;
    }
    .questionText {
      font-size: 14px;
      width: 60%;
      overflow: hidden;
      text-overflow: ellipsis; //文本溢出显示省略号
      white-space: nowrap; //文本不会换行
    }
    .finish {
      border: 1px solid #4064ef;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      text-align: center;
      font-size: 16px;
      line-height: 18px;
      margin: 2px 0 0 10px;
      color: #61a0ff;
    }
  }

  .workContent {
    padding: 20px;
    background: #fff;
    border-radius: 8px 8px 8px 8px;
    .option {
      margin: 10px;
    }
    .title {
      margin: 20px 0;
      display: flex;
      align-items: center;
      span {
        margin: 0 10px 0 30px;
        color: #ff7b16;
      }
    }
    .scoreInput {
      width: 100px;
      border-radius: 8px 8px 8px 8px;
    }
  }
  .bottom {
    padding: 20px 0;
    background: #fff;
    text-align: end;
    box-shadow: 0px -3px 3px 1px rgba(41, 90, 255, 0.16);
    margin-top: 15px;
    border-radius: 8px 8px 8px 8px;
    .submitWork {
      margin-right: 50px;
    }
  }
}
</style>
